import { useStaticQuery, graphql } from 'gatsby';

export const useSimpleGalleryQuery = () => {
  const galleryQuery = useStaticQuery(graphql`
    query MyQuery {
      certification: allFile(
        filter: { relativeDirectory: { eq: "certification-gallery" } }
      ) {
        nodes {
          id
          childImageSharp {
            fixed(width: 325) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }

      goverment: allFile(
        filter: { relativeDirectory: { eq: "goverment-gallery" } }
      ) {
        nodes {
          id
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }

      products: allFile(
        filter: { relativeDirectory: { eq: "product-gallery" } }
      ) {
        nodes {
          id
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `);
  return galleryQuery;
};
