import React from 'react';
import visa from '../../content/assets/visa.svg';
import mastercard from '../../content/assets/mastercard.svg';
import ath from '../../content/assets/ath_card.svg';
import amex from '../../content/assets/amex.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useSiteMetadata } from '../hooks/useSiteMetadata';

const Payments = () => {
  // const { primaryColor } = useSiteMetadata();
  return (
    <section className="px-8 py-12 bg-gray-200 md:px-12">
      <div className="container">
        <h2 className="mb-8 text-2xl font-bold text-center md:text-3xl">
          Formas de pago
        </h2>
        <div className="flex justify-between max-w-sm mx-auto sm:max-w-md md:max-w-lg">
          <img
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            src={visa}
            alt="Pago con Tarjeta Visa"
          />
          <img
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            src={mastercard}
            alt="Pago con tarjeta MasterCard"
          />
          <img
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            src={amex}
            alt="Pago con Tarjeta American Express"
          />
          <img
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            src={ath}
            alt="Pago con tarjeta ATH"
          />
        </div>
        <ul className="grid max-w-md grid-cols-2 mx-auto mt-8">
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            Visa
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            MasterCard
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            American Express
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            ATH
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            Efectivo
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            Cheque de gerente
          </li>
          <li>
            <FontAwesomeIcon
              icon="check-circle"
              className={`text-green-500 mr-2`}
            />
            Giro postal
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Payments;
