import React from 'react';
import Gallery from '@browniebroke/gatsby-image-gallery';

const LightBoxGallery = ({ images, galleryTitle, galleryDescription }) => {
  return (
    <section className="mx-8 mb-16 lg:mb-28">
      <div className="container max-w-5xl mx-auto">
        <h2 className="mb-4 text-2xl font-bold text-center md:text-3xl md:mb-6">
          {galleryTitle}
        </h2>
        {galleryDescription && (
          <div className="mb-6 -mx-2 leading-relaxed">{galleryDescription}</div>
        )}
        <Gallery
          images={images}
          colWidth={50}
          mdColWidth={33}
          gutter="0.4rem"
        />
      </div>
    </section>
  );
};

export default LightBoxGallery;
