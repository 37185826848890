import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceListItem = ({ children }) => {
  return (
    <li className="flex">
      <FontAwesomeIcon
        icon="check-circle"
        className={`text-green-500 mt-1 mr-2`}
      />
      {children}
    </li>
  );
};

export default ServiceListItem;
