import { useStaticQuery, graphql } from 'gatsby';

export const useGalleryQuery = () => {
  const GalleryQuery = useStaticQuery(graphql`
    query {
      inter: allFile(
        filter: { relativeDirectory: { eq: "comercial-inter-gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 340, srcSetBreakpoints: [320, 375, 414]) {
                ...GatsbyImageSharpFluid_withWebp
              }

              full: fluid(
                maxWidth: 992
                quality: 85
                srcSetBreakpoints: [320, 414, 576, 768, 992]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      industrial: allFile(
        filter: { relativeDirectory: { eq: "industrial-gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 340, maxHeight: 227) {
                ...GatsbyImageSharpFluid_withWebp
              }

              full: fluid(
                maxWidth: 992
                quality: 85
                srcSetBreakpoints: [576, 768, 992]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      comercial: allFile(
        filter: { relativeDirectory: { eq: "comercial-gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 340, maxHeight: 227) {
                ...GatsbyImageSharpFluid_withWebp
              }

              full: fluid(
                maxWidth: 992
                quality: 85
                srcSetBreakpoints: [576, 768, 992]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      residencial: allFile(
        filter: { relativeDirectory: { eq: "residencial-gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 340, maxHeight: 227) {
                ...GatsbyImageSharpFluid_withWebp
              }

              full: fluid(
                maxWidth: 992
                quality: 85
                srcSetBreakpoints: [576, 768, 992]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return GalleryQuery;
};
